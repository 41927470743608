import React from 'react';
import styled from 'styled-components';
// import Loader from "react-js-loader";
import Lottie from "lottie-react";
import loading from "../../assets/animations/loading.json";

export default function Loader({ isLoading }) {
    const width = window.innerWidth;
     console.log("isLoading",isLoading);

    
    return (
        isLoading &&
        <LoaderDiv>
            {/* <Loader type="bubble-spin" bgColor="#df8f17" color="#df8f17" title={"Loading ..."} size={100} /> */}
            {/* <ThreeDots color="#df8f17" secondaryColor="#df8f17" width={80}/> */}
          <Lottie
                              style={{
                                width: width > 768 ? "40%" : "60%"
                              }}
                              animationData={loading}
                              loop={true}
                            />
        </LoaderDiv>
    );
}

const LoaderDiv = styled.div`
    background-color: rgba(255, 255, 255, 1); /* Adjust the alpha value (0.1 for 10% opacity) */
    filter: blur(0px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjusted to full viewport height */
    position: fixed; /* Ensures it stays centered even when scrolling */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
`;
