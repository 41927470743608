import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { store } from "../../shared";
import styled from "styled-components";
// import AddressInput from "../AddressInput/AddressInput";
import {
  setAdresses,
  setDeliveryAdress,
  setDeliveryInformation,
  setDisable,
  setLocation,
  setOutOfRange,
  setSelectedAdresse,
} from "../../shared/slice/restaurant/RestaurantSlice";
// import { getProductByStoreByMode } from "../../shared/slice/restaurant/RestaurantService";
// import { useNavigate } from "react-router-dom";
// import { setOrder } from "../../shared/slice/order/OrderSlice";
// import { resetPromo } from "../../shared/slice/promos/PromosSlice";
// import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import {
  setModeDelivery,
} from "../../shared/slice/ModalMode/ModalMode";
// import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import NewAddresse from "../Checkout/NewAddresse";
import {
  DeleteDeliveryAddress,
  GetAdressDeliveryByUserId,
} from "../../shared/slice/DeliveryAdresse/DeliveryAdresseService";
import { useTranslation } from "react-i18next";


export default function ModalDelivery() {
  const [loading, setLoading] = useState(true);
  const [add, setAdd] = useState(false);
  const modeDelivery = useSelector((state) => state.modalMode.modeDelivery);
  const modalPrincipal = useSelector(
    (state) => state.modalLogin.modalPrincipal
  );
  const loginModal = useSelector((state) => state.modalLogin.modalLogin);
  const location = useSelector((state) => state.restaurant.location);
  const outOfRange = useSelector((state) => state.restaurant.outOfRange);
  const modalSignup = useSelector((state) => state.modalLogin.modalSignup);
  const selectedAdresse = useSelector(
    (state) => state.restaurant.selectedAdresse
  );
  console.log("selectedAdresse", selectedAdresse);

  const deliveryAdress = useSelector(
    (state) => state.restaurant.deliveryAdress
  );
  console.log("deliveryAdress", deliveryAdress);

  const restaurantSelected = useSelector(
    (state) => state.restaurant.restaurantSelected
  );
  const loggedInUser = useSelector(
    (state) => state.authentification.loggedInUser
  );

  const { t } = useTranslation();
  const adresses = useSelector((state) => state.restaurant.adresses);
  const scroll = useSelector((state) => state.scroll.scroll);

  //get user adresses
  useEffect(() => {
    if (modeDelivery) {
      console.log("in");
      if (loggedInUser) {
        // store.dispatch(setScroll({ scroll: scroll + 1 }));
        const fetchDeliveryAdresses = async () => {
          try {
            const res = await GetAdressDeliveryByUserId(loggedInUser._id);
            store.dispatch(setAdresses(res));
            console.log("adresses", res);
            setLoading(false);
          } catch (err) {
            console.log(err);
          }
        };
        fetchDeliveryAdresses();
        setAdd(false);
      }
    }
  }, [modeDelivery, loggedInUser]);

  const handleCloseModeDelivery = () => {
    store.dispatch(setOutOfRange({ outOfRange: false }));
    store.dispatch(setSelectedAdresse([]));
    store.dispatch(setModeDelivery({ modeDelivery: false }));
    store.dispatch(setDisable({ disable: true }));
    // store.dispatch(setScroll({ scroll: scroll - 1 }));
    setAdd(false);
  };
  //Address delete
  const handleAddressDelete = async (userId, NameAddress, Type) => {
    try {
      const response = await DeleteDeliveryAddress(userId, NameAddress, Type);
      console.log("Address deleted successfully:", response);
      const res = await GetAdressDeliveryByUserId(loggedInUser._id);
      store.dispatch(setAdresses(res));
      return response;
    } catch (error) {
      console.error("Error deleting address:", error);
      throw error;
    }
  };
  return (
    <StyledModalDeliveryMode
      isOpen={modeDelivery}
      onRequestClose={handleCloseModeDelivery}
      modalPrincipal={modalPrincipal}
    >
      <LoginDiv>
        <div>
          <div>
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "55px",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  float: "left",
                  fontFamily: "QuickSand",
                  fontSize: "19px",
                }}
              >
                {t("Your Addresses")}
              </p>
              <NewAdresseButton
                onClick={() => {
                  setAdd(true);
                  store.dispatch(setOutOfRange({ outOfRange: false }));
                  store.dispatch(setSelectedAdresse([]));
                }}
              >
                <AddIcon style={{ fontSize: "21x", color: "green" }} />
              </NewAdresseButton>
            </div>
            <table style={{ width: "100%", marginBottom: "16px" }}>
              <tbody>
                {loading ? (
                  <tr>
                    <td>Loading...</td>
                  </tr>
                ) : adresses && adresses.length > 0 ? (
                  <>
                    {adresses.map((adresse, index) => (
                      <>
                        {/* {console.log(adresses)} */}
                        <tr
                          key={index}
                          style={{
                            margin: "7px",
                            cursor: "pointer",
                            backgroundColor: "#fff9",
                            borderRadius: "10px",
                            boxShadow:
                              selectedAdresse.includes(
                                `adresse-${adresse.Nameaddress}-${adresse.Type}`
                              ) && outOfRange
                                ? "rgba(255, 1, 1, 0.856) 0px 0px 0px 1px inset, rgba(255, 1, 1, 0.856) 0px 0px 0px 1px"
                                : selectedAdresse.includes(
                                    `adresse-${adresse.Nameaddress}-${adresse.Type}`
                                  )
                                ? "var(--primaryColor) 0px 0px 0px 1px inset, var(--primaryColor) 0px 0px 0px 1px"
                                : "",
                          }}
                          id={`adresse-${adresse.Nameaddress}-${adresse.Type}`}
                          onClick={() => {
                            store.dispatch(
                              setDeliveryAdress({
                                deliveryAdress: adresse.Nameaddress,
                              })
                            );
                            store.dispatch(
                              setDeliveryInformation({
                                deliveryInformation: {
                                  type: adresse.Type,
                                  note: adresse.Note,
                                },
                              })
                            );
                            store.dispatch(
                              setSelectedAdresse([
                                `adresse-${adresse.Nameaddress}-${adresse.Type}`,
                              ])
                            );
                            store.dispatch(
                              setLocation({
                                label: adresse.Nameaddress,
                                value: {
                                  lat: adresse.Lat,
                                  lon: adresse.Lon,
                                },
                              })
                            );
                            setAdd(false);
                          }}
                        >
                          <td
                            style={{
                              width: "50px",
                              paddingTop: "10px",
                              borderRadius: "10px 0px 0px 10px",
                            }}
                          >
                            <AccessTimeIcon
                              style={{
                                color: "rgb(134, 128, 119)",
                              }}
                            />
                          </td>
                          <td
                            style={{
                              float: "left",
                              paddingTop: "10px",
                              paddingBottom:"5px",
                              fontSize: "16px",
                              fontFamily: "roboto",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width: "100%",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "QuickSandBold",
                                }}
                              >
                                {adresse.Type}
                              </p>
                              <div style={{ textAlign: "left" }}>
                                {adresse.Nameaddress}
                              </div>
                              {adresse.Note !== "" && (
                                <div>({adresse.Note})</div>
                              )}
                            </div>
                          </td>
                          <td
                            style={{
                              width: "50px",
                              paddingTop: "10px",
                              borderRadius: "0px 10px 10px 0px",
                            }}
                          >
                            <DeleteIcon
                              style={{
                                color: "rgb(134, 128, 119)",
                              }}
                              onClick={() => {
                                handleAddressDelete(
                                  loggedInUser._id,
                                  adresse.Nameaddress,
                                  adresse.Type
                                );
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="3" style={{ margin: "10px 0px" }}>
                      <p style={{ color: "#9c9ea5" }}>{t("empty")}</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/*-------------out of range------------- */}
          {!add && outOfRange ? (
            <div style={{ marginTop: "10px" }}>
              <span className="out-of-range-message">
                {t("this address is out of delivery range")}
              </span>
            </div>
          ) : null}
        </div>
      </LoginDiv>

      {/* <div>
          {deliveryAdress ? (
            <div>
              <div style={{ marginBottom: "12px", marginTop: "12px" }}>
                <p
                  style={{
                    textAlign: "center",
                    paddingLeft: "5px",
                    marginTop: "16px",
                    fontSize: "18px",
                    fontFamily: "Roboto, sans-serif",
                    color: "#333",
                    border: "2px solid #ddd",
                    borderRadius: "10px",
                    backgroundColor: "#ffffff",
                    padding: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Selected address : <bn></bn>
                  <b style={{color:"red",marginLeft:"5px"}}>{deliveryAdress}</b>
                </p>
              </div>
              <NewAdresseButton
                onClick={() => {
                  setAdd(true);
                  store.dispatch(setOutOfRange({ outOfRange: false }));
                  store.dispatch(setSelectedAdresse([]));
                }}
              >
                {t("Changez votre adresse")}
                <EditIcon
                  style={{ marginLeft: "10px", fontSize: "21x", color: "grey" }}
                />
              </NewAdresseButton>
            </div>
          ) : (
            <NewAdresseButton
              onClick={() => {
                setAdd(true);
                store.dispatch(setOutOfRange({ outOfRange: false }));
                store.dispatch(setSelectedAdresse([]));
              }}
            >
              <AddIcon style={{ fontSize: "22px", color: "green" }} />
              {t("Ajouter une adresse")}
            </NewAdresseButton>
          )}*/}
      
      <div
        style={{
          boxShadow:add ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" : "" ,
        padding: "16px"
        }}
      >
        <div style={{ display: !add ? "none" : "" }}>
        <div style={{ margin: "8px 0px" }}>
          <p
            style={{
              textAlign: "left",
              paddingLeft: "5px",
              marginTop: "16px",
              fontSize: "17px",
            }}
          >
            Enter your Delivery Adress :
          </p>
        </div>
      </div>

        <NewAddresse add={add} setAdd={setAdd} />
      </div>
      <ButtonClose
        onClick={() => {
          handleCloseModeDelivery();
        }}
      >
        <ArrowBackIcon style={{ fontSize: "27px" }} />
      </ButtonClose>
    </StyledModalDeliveryMode>
  );
}
function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
}
const StyledModalDeliveryMode = styled(ReactModalAdapter).attrs((props) => ({
  modalClassName:
    props.loginModal || props.modalPrincipal || props.modalSignup
      ? "ModalDelivery2"
      : "ModalDelivery",
  overlayClassName:
    props.loginModal || props.modalPrincipal || props.modalSignup
      ? "ModalDelivery2"
      : "OverlayDelivery",
}))`
  .ModalDelivery {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    height: auto;
    width: auto;
    min-width: 500px;
    min-height: 400px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .ModalDelivery {
      width: 90%;
      min-width: 90%;
      max-height: 90%;
      overflow-y: auto;
    }
  }

  .ModalDelivery2 {
    display: none;
  }

  .OverlayDelivery {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
`;
const LoginDiv = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonClose = styled.button`
  position: absolute;
  top: 5px;
  left: 3px;
  padding-right: 10px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;
const NewAdresseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
const Line = styled.div`
  // display: inline-block;
  border-bottom: 3px solid #cccccc;
  width: 70%;
  margin-left: 15px;
  margin-right: 15px;
  // margin-top: 30px;
  @media (max-width: 768px) {
    width: 40%;
  }
`;
