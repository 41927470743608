import { createSlice } from "@reduxjs/toolkit";
export const ModalNotificationInitialState = {
    ShowPopup: false,
    Notification: [],
    Test:true,
    Address:[],
};

export const ModalNotificationSlice = createSlice({
    name: "ModalNotification",
    initialState: ModalNotificationInitialState,
    reducers: {
        setShowPopup: (state, action) => {
            state.ShowPopup = action.payload.ShowPopup
        },
        setNotification: (state, action) => {

            state.Notification=action.payload.Notification;
          },
        setTest:(state,action)=>{
            state.Test=action.payload.Test
        },
        setAddress:(state,action)=>{
            state.Address=action.payload.Address       
        }
    },
});

export const { setShowPopup, setNotification,setTest ,setAddress} = ModalNotificationSlice.actions;
