import { createSlice } from "@reduxjs/toolkit";

export const RestaurantInitialState = {
    restaurant: [],
    restaurantSelected: undefined,
    restaurantSlug: undefined,
    menu: undefined,
    product: undefined,
    mode: [],
    modeSelectedId: undefined,
    modeId: undefined,
    disable: false,
    deliveryAdress: "",
    deliveryInformation :{
        type: "",
        note: "",
    },
    checkedIndices: [],
    checkedOptions: [],
    taux: null,
    frais: null,
    apply: "",
    modeName:"Dine-in",
    adresses: [],
    selectedAdresse:[],
    location: null,
    outOfRange :false,
    openModal:{
        product: undefined,
        open: false,
      },
    editOrder:{
        open: false,
        objectOptions:[],
        checkedIndices: [],
        checkedOptions: [],
        counter:{},
        size:"",
        orderQuantity:0,
        note:undefined,
    }
};


export const restaurantSlice = createSlice({
    name: "restaurant",
    initialState: RestaurantInitialState,
    reducers: {
        setRestaurant: (state, action) => {
            state.restaurant = action.payload.restaurant
        }, 
        setSpecialite: (state, action) => {
            state.specialite = action.payload.specialite
        },
        setRestaurantSelected: (state, action) => {
            state.restaurantSelected = action.payload.restaurantSelected
        },
        setRestaurantSlug: (state, action) => {
            state.restaurantSlug = action.payload.restaurantSlug
        },
        setMenu: (state, action) => {
            state.menu = action.payload.menu
        },
        setProduct: (state, action) => {
            state.product = action.payload.product
        },
        setMode: (state, action) => {
            state.mode = action.payload.mode
        },
        setModeSelectedId: (state, action) => {
            state.modeSelectedId = action.payload.modeSelectedId
        },
        setModeId: (state, action) => {
            state.modeId = action.payload.modeId
        },
        setDisable: (state, action) => {
            state.disable = action.payload.disable
        },
        setAdresses: (state, action) => {
            state.adresses = action.payload
        },
        setSelectedAdresse: (state, action) => {
            state.selectedAdresse = action.payload
        },
        setDeliveryAdress: (state, action) => {
            state.deliveryAdress = action.payload.deliveryAdress
        },
        setDeliveryInformation: (state, action) => {
            state.deliveryInformation = action.payload.deliveryInformation
        },

        setLocation: (state, action) => {
            state.location = action.payload
        },
        setOutOfRange: (state, action) => {
            state.outOfRange = action.payload.outOfRange
        },
        setCheckedIndices: (state, action) => {
            state.checkedIndices = action.payload.checkedIndices
        },
        setCheckedOptions: (state, action) => {
            state.checkedOptions = action.payload.checkedOptions
        },
        setTaux: (state, action) => {
            state.taux = action.payload.taux
        },
        setFrais: (state, action) => {
            state.frais = action.payload.frais
        },
        setApply: (state, action) => {
            state.apply = action.payload.apply
        },  
        setModeName: (state, action) => {
            state.modeName = action.payload.modeName
        },
        setOpenModal: (state, action) => {
            state.openModal = action.payload
        },
        setEditOrder: (state, action) => {
            state.editOrder = action.payload
        },
    },
});

export const { setRestaurantSlug,setSpecialite,setRestaurant, setRestaurantSelected, setMenu, setProduct, setMode, setModeSelectedId, setModeId, setDisable, setDeliveryAdress, setCheckedIndices, setCheckedOptions, setTaux, setFrais, setApply ,setModeName, setAdresses,setSelectedAdresse,setLocation, setOutOfRange, setDeliveryInformation,setOpenModal,setEditOrder} = restaurantSlice.actions;
