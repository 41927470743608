import { createSlice } from "@reduxjs/toolkit";

export const ModalStoresInitialState = {
    storesModal:false,
    OpenCheckOut:false,
};

export const modalStoresSlice = createSlice({
    name: "modalStores",
    initialState: ModalStoresInitialState,
    reducers: {
      
        setStoresModal: (state, action) => {
            state.storesModal = action.payload.storesModal
        },
        setOpenCheckOut: (state, action) => {
            state.OpenCheckOut = action.payload.OpenCheckOut
        },
    },
});

export const { setStoresModal,setOpenCheckOut } = modalStoresSlice.actions;
