import { SseURI } from "../..";
import { Executor } from "../../Executor";

export const postNotification = (orderId, status, userId) => {
    return Executor({
        method: 'post',
        url: `${SseURI}/notifications/${orderId}/${status}/${userId}`,
        isSilent: false,
        withErrorToast: false,
        withSuccessToast: false,
    });
}
 
export const getNotification = (userid) => {
    return Executor({
        method: 'get',  
        url: SseURI + `/notifications/${userid}`,
        isSilent: true,
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const putNotification = (orderid,userid) => {
    return Executor({
        method: 'put',
        url: SseURI +`/notifications/${orderid}/${userid}`,
        isSilent: true,
        withErrorToast: false,
        withSuccessToast: false,
    });
}

export const putNotificationservice = (orderId, status, userId) => {
    return Executor({
        method: 'put',
        url: SseURI +`/notifications/${orderId}/${status}/${userId}`,
        isSilent: false,
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const deleteNotification = (userid) => {
    return Executor({
        method: 'delete',
        url: SseURI +`/notifications/${userid}`,
        isSilent: false,
        withErrorToast: false,
        withSuccessToast: false,
    });
}