import { createSlice } from "@reduxjs/toolkit";

export const ModalLoginInitialState = {
    modalPrincipal: false,
    modalLogin: false,
    modalSignup: false,
    modalSendEmail: false,
    modalResetPassword: false,
    emailExist: null,
    email: "",
    ModelGuest:false,
    IsKioskLikely:false
};



export const modalLoginSlice = createSlice({
    name: "modalLogin",
    initialState: ModalLoginInitialState,
    reducers: {
        setModalPrincipal: (state, action) => {
            state.modalPrincipal = action.payload.modalPrincipal
        },
        setModalLogin: (state, action) => {
            state.modalLogin = action.payload.modalLogin
        },
        setModalSignup: (state, action) => {
            state.modalSignup = action.payload.modalSignup
        },
        setModelGuest: (state, action) => {
            state.ModelGuest = action.payload.ModelGuest
        },
        setModalSendEmail: (state, action) => {
            state.modalSendEmail = action.payload.modalSendEmail
        },
        setModalResetPassword: (state, action) => {
            state.modalResetPassword = action.payload.modalResetPassword
        },
        setEmailExist: (state, action) => {
            state.emailExist = action.payload.emailExist
        },
        setEmail: (state, action) => {
            state.email = action.payload.email
        },
        setIsKioskLikely: (state, action) => {
            state.IsKioskLikely = action.payload.IsKioskLikely
        },

    },
});

export const { setModalPrincipal, setModalLogin, setModalSignup,setEmailExist, setEmail, setModalSendEmail,setModelGuest,setIsKioskLikely, setModalResetPassword } = modalLoginSlice.actions;
