import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { BaseURI, ImageURI, store } from "../../../shared";
import { setScroll } from "../../../shared/slice/scroll/ScrollSlice";
import {
  setNiveauPromos,
  setSizeSelected,
} from "../../../shared/slice/promos/PromosSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import "../../Menu/productModal/ProductModal.css";
function PromoDescriptionModal({ selectedPromo, setSelectedPromo }) {
  const dispatch = useDispatch();
  const [selectedSize, setSelectedSize] = useState(
    selectedPromo.promo.promos[0].AcceptedSizes[0]
  );

  return (
    <div>
      <StyledModalPrincipal isOpen={selectedPromo.promoDiscription}>
        <button
          className="btn-close button-close"
          style={{}}
          onClick={() => {
            setSelectedPromo({
              state: false,
              promo: undefined,
              promoDiscription: false,
              products: [{ product: undefined, selected: false }],
            });
            store.dispatch(setScroll({ scroll: 0 }));
          }}
        ></button>
        <div
          style={{
            minHeight: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <img
              src={`${ImageURI}/combined-uploads/${selectedPromo.promo.image}`}
              alt="Promo"
            />
            <h2 style={{ margin: "16px 0px", fontSize: "xx-large" }}>
              {selectedPromo.promo.name}
            </h2>
            <p>{selectedPromo.promo.description}</p>
          </div>
          <div style={{marginBottom:"1.9rem"}}>
          <p>{selectedPromo.promo.description}</p>
            {selectedPromo.promo.promos[0].AcceptedSizes.map((grp, index) => (
              <div
                className="btn-group"
                role="group"
                aria-label="Basic radio toggle button group"
                style={{height:"3.8rem"}}
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio2"
                  value={grp}
                  id={index}
                  autoComplete="off"
                  onChange={() => setSelectedSize(grp)}
                  checked={selectedSize === grp}
                />
                <label
                  className={`btn btn-size ${
                    selectedSize === grp ? "btnSizeClicked" : ""
                  }`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: "#eb1700",
                  }}
                  htmlFor={index}
                >
                  <span style={{ fontWeight: "bold", fontSize: "22px" }}>
                    {grp}{" "}
                  </span>
                </label>
              </div>

             
            ))}
          </div>
          <div>
            <ButtonNext
              onClick={() => {
                dispatch(setSizeSelected({ SizeSelected: selectedSize }));

                setSelectedPromo((prevState) => ({
                  ...prevState,
                  state: true,
                  promoDiscription: false,
                }));

                // Set the NiveauPromoS to 0.

                dispatch(setNiveauPromos({ NiveauPromos: 0 }));
              }}
            >
              Continue
            </ButtonNext>
          </div>
        </div>
      </StyledModalPrincipal>
    </div>
  );
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
}
const StyledModalPrincipal = styled(ReactModalAdapter).attrs({
  modalClassName: "ModalPromoDiscreption",
  overlayClassName: "OverlayPromoDiscreption",
})`
  .ModalPromoDiscreption {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    // padding: 15px 20px 2px;
    border-radius: 15px;
    width: 500px;
    min-width: 400px;
    max-height: 95%;
    min-height: 50%;
    text-align: center;
    outline: none;
    overflow-y: hidden;
    @media (max-width: 768px) {
      min-width: 200px;
      width: 95%;
      max-height: 100%;
      border-radius: 15px;
    }
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .OverlayPromoDiscreption {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
`;
const ButtonNext = styled.button`
  background-color: var(--primaryColor);
  margin-bottom: 10px;
  border-radius: 15px;
  color: white;
  width: 120px;
  height: 40px;
  // position: absolute;
  // bottom: 10px;
`;
export default PromoDescriptionModal;
