export default {
    //home.js
    "Our Partners": "Nuestros socios",

    //AdressInput.js
    "type your address": "escribe tu dirección",

    //StoreScreen.js
    "km away": " km ",
    "Closed 🔴": "Cerrado 🔴",
    "Open 🟢": "Abierto 🟢",

    //DateModel.js
    "Monday": "Lunes",
    "Tuesday": "Martes",
    "Wednesday": "Miércoles",
    "Thursday": "Jueves",
    "Friday": "Viernes",
    "Saturday": "Sábado",
    "Sunday": "Domingo",
    "Closed": "Cerrado",
    "Service Hours": "Horas de servicio",
    "Language": "Idioma",

    // menu.js
    "Our promos": "Nuestras promociones",

    //selectoption.js
    "(optional)": "(Opcional)",
    "Required": "Requerido",
    "select up to": "seleccionar hasta",

    // SubmitOrder.js
    "select at least": "seleccionar al menos",
    "of": "de",
    "Quantity": "Cantidad",
    "change your order": "cambia tu pedido",

    //submitOrderPromo.js
    "Finish": "Terminar",
    "Next": "Siguiente",

    //ModelPrincipal.js
    "Email Address": "Dirección de correo electrónico",
    "Continue": "Continuar",
    "Or": "O",
    "Continue with Google": "Continuar con Google",
    "Email does not exist": "El correo electrónico no existe",

    //ModalSignup.js
    "First Name": "Nombre",
    "Last Name": "Apellido",
    "Email": "Correo electrónico",
    "Enter Phone Number": "Introduce el número de teléfono",
    "Password": "Contraseña",
    "Confirm Password": "Confirmar contraseña",
    "Passwords do not match": "Las contraseñas no coinciden",
    "Signup": "Registrarse",
    "Sign up": "Registrarse",
    "Male": "Masculino",
    "Female": "Femenino",

    //ModelLogin.js
    "Login": "Iniciar sesión",
    "Sign in": "Iniciar sesión",
    "Forget password": "Olvidé mi contraseña",
    "Add to cart": "Agregar al carrito",
    "kitchen note": "Nota de cocina",
    "Leave your note about food here": "Deja aquí tu nota sobre la comida",

    // Cart.js
    "Your Order": "Tu pedido",
    "Checkout ": "Pagar",
    "Checkout": "Pagar",
    "tax-exclusive": "sin impuestos",
    "ASAP": "lo antes posible",
    "By checking this box, you confirm that your order to": "Al marcar esta casilla, confirmas que tu pedido a",
    "This action is irreversible and commits to the purchase as outlined.": "Esta acción es irreversible y compromete la compra como se describe.",
    "Minimum order value": "Valor mínimo de pedido",
    " for ": " para el modo",
    "is ": "es ",

    //checkout.js
    "Contact": "Contacto",
    "Name": "Nombre",
    "Email": "Correo electrónico",
    "phone number": "número de teléfono",
    "Order in Advance": "Pedir por adelantado",
    "Reset": "Restablecer",
    "Month D, YYYY HH:MM": "Mes D, AAAA HH:MM",
    "Payment Method": "Método de pago",
    "Cash": "Efectivo",
    "EPT": "TPE",
    "Credit/Debit Card": "Tarjeta de crédito/débito",
    "Place Your Order": "Realizar pedido",

    //modalresetPassword.js
    "Email has been send, check your email!": "¡El correo electrónico ha sido enviado, revisa tu correo!",
    "Resend Email": "Reenviar correo",

    //firstPage.js
    "All Stores": "Todos los restaurantes",
    "Nearby Stores": "Restaurantes cercanos",

    //ModelEmailSend.js
    "Email has been send, check your email!": "¡El correo electrónico ha sido enviado, revisa tu correo!",
    "Resend Email": "Reenviar correo",

    //AllStoresScreen.js
    "All Stores": "Todos los restaurantes",

    //ModalModes.js
    "Select Your Mode": "Selecciona tu modo",
    "Yes": "Sí",
    "No": "No",

    //SelectStore.js
    "Select Store": "Selecciona un restaurante",
    "Confirm": "Confirmar",
    "Delivery": "Entrega",
    "Takeaway": "Para llevar",
    "Dine-in": "Comer en el local",

    //ProductModel
    "leave your additional note": "deja tu nota adicional",
    "Additional note": "Nota adicional",

    //SubmitOrder.js
    "select at least": "seleccionar al menos",
    "of": "de",
    "Quantity": "Cantidad",

    //OrdersHistory
    "TIME": "TIEMPO",
    "MODE": "MODO",
    "STATUS": "ESTADO",
    "PAYMENT": "PAGO",
    "TOTAL": "TOTAL",
    "VIEW": "VER",
    "accepted": "aceptado",
    "ready": "listo",
    "rejected": "rechazado",
    "missed": "perdido",
    "pending": "Pendiente",

    //OrderHistoryModal.js
    "(Without service fees)": "(Sin tarifas de servicio)",
    "ORDER ID": "ID DE PEDIDO",
    "Total price": "Precio total",
    "Add note": "Agregar nota",
    "Leave your note here": "Deja tu nota aquí",
    "Order Time": "Hora de pedido",
    "Schedule Order": "Programar pedido",
    "Minutes": "Minutos",
    "Seconds": "Segundos",
    "left.": "restantes.",
    "We regret to inform you that your order has been Missed": "Lamentamos informarte que tu pedido ha sido perdido",
    "We regret to inform you that your order has been Rejected": "Lamentamos informarte que tu pedido ha sido rechazado",
    "We are pleased to inform you that your order has been Accepted": "Nos complace informarte que tu pedido ha sido aceptado",
    "We are pleased to inform you that your order is Ready": "Nos complace informarte que tu pedido está listo",
    "Free": "Gratis",
    "By checking this box, you confirm that you are certain about proceeding with this specific order to": "Al marcar esta casilla, confirmas que estás seguro de proceder con este pedido específico a",
    "This action is irreversible and commits to the purchase as outlined. Please review your selections carefully before confirming": "Esta acción es irreversible y compromete la compra como se describe. Por favor, revisa tus selecciones cuidadosamente antes de confirmar",
    "The restaurant is closed, ASAP mode is not available": "El restaurante está cerrado, el modo ASAP no está disponible.",

    "Craving something delicious? Order your favorite meals from our platform and enjoy fast, easy, and reliable food delivery. Discover a wide variety of cuisines, exclusive deals, and hassle-free ordering. Satisfaction guaranteed with every bite.": "¿Antojo de algo delicioso? Ordena tus platos favoritos desde nuestra plataforma y disfruta de una entrega rápida, fácil y confiable. Descubre una gran variedad de cocinas, ofertas exclusivas y un pedido sin complicaciones. Satisfacción garantizada en cada bocado.",
    "Get connected with us on social networks": "Conéctate con nosotros en redes sociales",
    "Pick your 1st choice": "Elige tu primera opción",
    "Previous choices": "Opciones anteriores",
    "Now pick your": "Ahora elige tu",
    "choice.": "opción.",
    "th": "º",
    "rd": "º",
    "nd": "º",
    "Chosen size": "Tamaño elegido",
    "Cart will be cleared, are you sure": "El carrito se vaciará, ¿estás seguro?",
    "From ": "Desde "
}
