export default {
    //home.js
    "Our Partners": "Nos partenaires",

    //AdressInput.js
    "type your address": "tapez votre adresse",

    //StoreScreen.js
    "km away": " Km ",
    "Closed 🔴": "Fermée 🔴",
    "Open 🟢": "Ouvert 🟢",

    //DateModel.js
    "Monday": "Lundi",
    "Tuesday": "Mardi",
    "Wednesday": "Mercredi",
    "Thursday": "Jeudi",
    "Friday": "Vendredi",
    "Saturday": "Samedi",
    "Sunday": "Dimanche",
    "Closed": "Fermée ",
    "Service Hours": "Heures de service",
     "Language":"langue",
    // menu.js
    "Our promos": "Nos promotions",

    //selectoption.js
    "(optional)": "(Optionnel)",
    "Required" : "Obligatoire",
    "select up to" : " sélectionner jusqu'à ",
   // SubmitOrder.js
"select at least":"sélectionner au moin",
"of":"de",
"Quantity":"Quantity",
"change your order": "changez votre ordre",

    //submitOrderPromo.js
    "Finish" : "Terminer",
    "Next":"Suivant",

    //ModelPrincipal.js
    "Email Address" : "Adresse mail",
    "Continue":"Continuer",
    "Or":"Ou",
    "Continue with Google":"Continuer avec Google",
    "Email does not exist":"Email n'existe pas",

    //ModalSignup.js
    "First Name" : "Prénom",
    "Last Name": "Nom de famille",
    "Email" :"E-mail",
    "Enter Phone Number" : "Entrez le numéro de téléphone",
    "Password":"Mot de passe",
    "Confirm Password":"Confirmez le mot de passe",
    "Passwords do not match":"Les mots de passe ne correspondent pas",
    "Signup": "S'inscrire",
    "Sign up": "S'inscrire",
    "Male" : "Homme",
    "Female" : "Femme",

    //ModelLogin.js
    "Login" : "Se connecter",
    "Sign in":"Se connecter",
    "Forget password":"Mot de passe oublié",
    "Add to cart" :"Ajouter au panier",
    "kitchen note":"Note cuisine",
    "Leave your note about food here":"Laissez ici votre note sur la nourriture",
    // Cart.js
    "Your Order":"Votre commande",
    "Checkout ":"Paiement ",
    "Checkout":"Paiement",
    "tax-exclusive":"HT",
    "ASAP":"dès que possible",
    "By checking this box, you confirm that your order to":"En cochant cette case, vous confirmez que votre commande à",
    "This action is irreversible and commits to the purchase as outlined.":"Cette action est irréversible et engage à l’achat tel que décrit.",
    "Minimum order value":"Valeur minimale de la commande",
    " for ":" pour le mode",
    "is ":"est ",

    //checkout.js
    "Contact" : "Contact",
    "Name" : "Nom",
    "Email": "E-mail",
    "phone number":"numéro de téléphone",
    "Order in Advance":"Commandez à l'avance",
    "Reset":"Réinitialiser",
    "Month D, YYYY HH:MM":"Mois J, AAAA HH:MM",
    "Payment Method":"Mode Paiement",
    "Cash":"Espèces",
    "EPT":"TPE",
    "Credit/Debit Card":"Carte de crédit / débit",
    "Place Your Order":"Passer votre commande",
     

    //modalresetPassword.js
    "Email has been send, check your email!":"E-mail a été envoyé, vérifiez votre e-mail !",
    "Resend Email":"Ré-envoyer l'email",
  //firstPage.js
     "All Stores":"Toutes les restaurant",
     'Nearby Stores':"Restaurant à proximité",
//ModelEmailSend.js
"Email has been send, check your email!":"e-mail a été envoyé, vérifiez votre e-mail !",
"Resend Email":"Ré-envoyer l'email",
// AllStoresScreen.js
"All Stores":"Toutes les restaurant",
// ModalModes.js
"Select Your Mode" :"Sélectionnez votre mode",
"Yes" :"Oui",
"No":"Non",
 //SelectStore.js
 "Select Store":"Choisi un restaurant",
 "Confirm" : "Confirmer",
 "Delivery":"Livraison",
 "Takeaway":"Emporter",
 "Dine-in":"Sur Place",

 //  ProductModel
"leave your additional note":"laissez votre note supplémentaire",
"Additional note":"Note supplémentaire",


// SubmitOrder.js
"select at least":"sélectionner au moin",
"of":"de",
"Quantity":"Quantity",



//OrdersHistory
"TIME":"TEMPS",
"MODE":"MODE",
"STATUS":"STATUT",
"PAYMENT":"PAIEMENT",
"TOTAL":"TOTALE",
"VIEW":"VOIR",
"accepted":"accepté",
"ready":"prêt",
"rejected":"rejetée",
"missed":"manquée",
"pending":"En attente",


//OrderHistoryModal.js
"(Without service fees)":"(Sans frais de service)",
"ORDER ID":"ID DE LA COMMANDE",
"Total price":"Prix ​​total",
"Add note":"Ajouter une note",
"Leave your note here":"Laissez votre note ici",
"Order Time":"Temps de commande",
"Schedule Order":"Planifier la commande",
"Minutes":"Minutes",
"Seconds":"Secondes",
"left.":"restantes.",
"We regret to inform you that your order has been Missed":"Nous avons le regret de vous informer que votre commande a été manquée",
"We regret to inform you that your order has been Rejected":"Nous avons le regret de vous informer que votre commande a été rejetée",
"We are pleased to inform you that your order has been Accepted":"Nous sommes heureux de vous informer que votre commande a été acceptée",
"We are pleased to inform you that your order is Ready":"Nous avons le plaisir de vous informer que votre commande est prête",
"Free":"Gratuit",
"By checking this box, you confirm that you are certain about proceeding with this specific order to":"En cochant cette case, vous confirmez que vous êtes certain de procéder à cette commande spécifique à",
"This action is irreversible and commits to the purchase as outlined. Please review your selections carefully before confirming":"Cette action est irréversible et engage l'achat tel que décrit. Veuillez examiner attentivement vos sélections avant de confirmer",
"The restaurant is closed, ASAP mode is not available":"Le restaurant est fermé, le mode ASAP n'est pas disponible.",





"Craving something delicious? Order your favorite meals from our platform and enjoy fast, easy, and reliable food delivery. Discover a wide variety of cuisines, exclusive deals, and hassle-free ordering. Satisfaction guaranteed with every bite.":"Envie de quelque chose de délicieux ? Commandez vos plats préférés sur notre plateforme et profitez d'une livraison rapide, facile et fiable. Découvrez une grande variété de cuisines, des offres exclusives et une commande sans tracas. Satisfaction garantie à chaque bouchée.",
"Get connected with us on social networks":"Connectez-vous avec nous sur les réseaux sociaux",
"Pick your 1st choice":"Choisissez votre 1er choix",
"Previous choices":"Choix précédents",
"Now pick your":"Choisissez maintenant votre",
"choice.":"choix.",
"th":"eme",
"rd":"eme",
"nd":"eme",
"Chosen size":"Taille choisie",
"Cart will be cleared , are you sure":"Le panier sera vidé, êtes-vous sûr",
"From ":"a partir de "
}

