import { createSlice } from "@reduxjs/toolkit";

export const ModalModeInitialState = {
    modeDelivery: false,
    modesModal:true,
};

export const modalModeSlice = createSlice({
    name: "modalMode",
    initialState: ModalModeInitialState,
    reducers: {
        setModeDelivery: (state, action) => {
            state.modeDelivery = action.payload.modeDelivery
        },
        setModesModal: (state, action) => {
            state.modesModal = action.payload.modesModal
        },
    },
});

export const { setModeDelivery,setModesModal } = modalModeSlice.actions;
