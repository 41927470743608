import React from "react";
import styled from "styled-components";

// Stepper component accepts the number of steps, active step, and custom styles
const Stepper = ({ steps, activeStep, styleConfig }) => {
  return (
    <StepperContainer>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <Step
            isActive={index === activeStep}
            isCompleted={index < activeStep}
            styleConfig={styleConfig}
            stepIndex={index + 1} // Display step index
          />
          {/* Render the "--" only between steps and change color if it's before the active step */}
          {index < steps.length - 1 && (
            <Dash
              isBeforeActive={index < activeStep} // Pass prop to check if Dash is before the active step
              dashColor={styleConfig.dashColor} // Pass dashColor explicitly
              activeDashColor={styleConfig.activeDashColor} // Pass activeDashColor explicitly
              dashSize={styleConfig.dashSize} // Pass dashSize explicitly
            >
              <div>▬</div>
            </Dash>
          )}
        </React.Fragment>
      ))}
    </StepperContainer>
  );
};

// Individual Step styles, based on whether it's active or completed
const Step = ({ isActive, isCompleted, styleConfig, stepIndex }) => {
  return (
    <StepCircle
      style={{
        backgroundColor: isActive
          ? styleConfig.activeBgColor
          : isCompleted
          ? styleConfig.completedBgColor
          : styleConfig.inactiveBgColor,
        width: styleConfig.size,
        height: styleConfig.size,
        fontFamily: styleConfig.fontFamily,
        fontSize: styleConfig.circleFontSize || "1em", // Default font size
      }}
    >
      {stepIndex}
    </StepCircle>
  );
};

// Stepper container styles
const StepperContainer = styled.div`
  margin-top: -5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

// Step circle styles
const StepCircle = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

// Dash (--) styles between steps
const Dash = styled.div`
  color: ${(props) =>
    props.isBeforeActive
      ? props.activeDashColor || "#000" // Color before the active step
      : props.dashColor || "#e0e0e0"}; // Default color
  font-size:0.8rem;
`;

// Default export of Stepper
export default Stepper;
