import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { login, verifyEmail } from "../../shared/slice/auth/AuthService";
import { useSelector } from "react-redux";
import { store } from "../../shared";
import { setLoggedInUser, setToken } from "../../shared/slice/auth/AuthSlice";

export default function VerifyEmail() {
    const { userId } = useParams()
    
    const navigate = useNavigate()
    const restaurantSelected = useSelector((state) => state.restaurant.restaurantSelected)
    useEffect(() => {
        const verifemail = async () => {
            
            await verifyEmail(userId).then(async (res) => {
                if (res.success === true) {
                    const response = await login({ email: res.user.email, isVerificationRoute: true })
                    if (response) {
                        store.dispatch(setToken({ token: response.token }))
                        store.dispatch(setLoggedInUser({ user: response.user }))

                        if(restaurantSelected===undefined){ 
                            navigate("/");  
                            //  window.location.reload();
                            }
                        else{
                        navigate("/select-store/" + restaurantSelected._id)
                    }
                    } else {
                      navigate("/page404")
                    }
                }

            }).catch(err => {
               navigate("/page404")
                console.log(err);
            })
        }
        verifemail()
    }, [])
}
