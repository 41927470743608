import { createSlice } from "@reduxjs/toolkit";

export const OrderInitialState = {
    order: [],
    OrderNotification: [],
    OrdersHistory: null,
    Favorites: []
};


export const orderSlice = createSlice({
    name: "order",
    initialState: OrderInitialState,
    reducers: {
        setOrder: (state, action) => {
            state.order = action.payload.order
        },
        setOrderNotification: (state, action) => {
            const newOrderNotification = JSON.parse(action.payload.OrderNotification);
            const existingIndex = state.OrderNotification.findIndex(notification => notification.orderId === newOrderNotification.orderId);
            if (existingIndex !== -1) {
                state.OrderNotification[existingIndex].status = newOrderNotification.status;
            } else {
                state.OrderNotification.push(newOrderNotification);
            }
        },
        deleteOrderNotification: (state) => {
            state.OrderNotification = []
        },
        setOrdersHistory: (state, action) => {
            state.OrdersHistory = action.payload.OrdersHistory
        },
        modifyOrderHistory: (state, action) => {
            const { orderId } = action.payload;
            const orderIndex = state.OrdersHistory.findIndex(order => order._id === orderId);

            const updatedOrdersHistory = [...state.OrdersHistory];
            updatedOrdersHistory[orderIndex].favorite = !updatedOrdersHistory[orderIndex].favorite
            state.OrdersHistory = updatedOrdersHistory
        },

        setOrderToOldOrders: (state, action) => {
            for (let i = 0; i < action.payload.order.length; i++) {
                state.order.push(action.payload.order[i])
            }
        },

        setFavoritesOrders: (state, action) => {
            state.Favorites = action.payload.Favorites
        },
    },
});

export const { setOrder, setOrderNotification, deleteOrderNotification, setOrdersHistory, setOrderToOldOrders, setFavoritesOrders, modifyOrderHistory } = orderSlice.actions;
