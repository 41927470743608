import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from '@mui/material/Dialog';
import { useSelector } from "react-redux";
import { BaseURI, ImageURI, store } from "../../shared";
import styled from "styled-components";
import { setOrder } from "../../shared/slice/order/OrderSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  deletePromos,
  resetPromo,
} from "../../shared/slice/promos/PromosSlice";
import { setModalPrincipal, setModelGuest } from "../../shared/slice/ModalLogin/ModalLoginSlice";
import Checkout from "../Checkout/Checkout";
import { useTranslation } from "react-i18next";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import {
   setEditOrder, 
   setOpenModal,
  } from "../../shared/slice/restaurant/RestaurantSlice";
import { useLocation, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
export default function Cart() {
  const { t } = useTranslation();
  const scroll = useSelector((state) => state.scroll.scroll);
  const [show, setShow] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceHt, setTotalPriceHt] = useState(0);
  const [modalCheckout, setModalCheckout] = useState(false);
  const [confirmationClearCart, setConfirmationClearCart] = useState(false)
  // const [frais, setFrais] = useState(null);
  // const [taux, setTaux] = useState(null);
  // const [apply, setApply] = useState("");
  // const [modeName, setModeName] = useState("");
  const handleClose = () => {
    setShow(false);
    setConfirmationClearCart(false)
    store.dispatch(setScroll({ scroll: 0 }));
  };
  const handleShow = () => setShow(true);
  const currency = getSymbolFromCurrency(
    useSelector((state) => state.restaurant.menu?.currency)
  );
  const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);
  const orders = useSelector((state) => state.order.order);
  const location = useLocation();
  const { delivery,vendeur,vendeurId } = location.state || {};

  const selectedPromos = useSelector((state) => state.promos.selectedPromos);
  // const restaurantSelected = useSelector(
  //   (state) => state.restaurant.restaurantSelected
  // );
  // const modeSelectedId = useSelector(
  //   (state) => state.restaurant.modeSelectedId
  // );
  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);
  const modeName = useSelector((state) => state.restaurant.modeName);
  const products = useSelector((state) => state.restaurant.product);
  const IDSTORE = useParams().id;

  useEffect(() => {
    if (show) {
      store.dispatch(setScroll({ scroll: 1 }));
    }
  }, [show]);

  // useEffect(() => {
  //   const getmode = async () => {
  //     if (modeSelectedId) {
  //       const mode = await getMode(modeSelectedId);
  //       if (mode.mode.applyTaux) {
  //         setTaux(mode.mode.taux);
  //       } else {
  //         setFrais(mode.mode.frais)
  //       };
  //       setApply(mode.mode.applicationType);
  //       setModeName(mode.mode.name);
  //     }
  //   };
  //   getmode();
  // }, [modeSelectedId, restaurantSelected._id]);

  // console.log(selectedPromos);

  /*  ---------l'ancien----------------*/

  useEffect(() => {
    let total = 0;
    let totalHt = 0;
    for (let i = 0; i < orders.length; i++) {
      // if (apply === "product") {
      //   total +=
      //     orders[i].price +
      //     (frais !== null ? (frais * orders[i].quantity) : orders[i].price * ((taux / 100) * orders[i].quantity));
      // } else {
      total += orders[i].price;
      totalHt += orders[i].priceHt;
      // }
      console.log("total",total,orders[i]);
    }
    
    // order.product.id
    for (let i = 0; i < selectedPromos.length; i++) {
      const bundlePrice = selectedPromos[i].promo.bundlePrice
      for (let j = 0; j < selectedPromos[i].products.length; j++) {
        const item = selectedPromos[i].products[j];
      
        // if (apply === "product") {
        //   total +=
        //     item.price_after_discount +
        //     (frais !== null ? (frais * item.quantity) : item.price * ((taux / 100) * item.quantity));
        // } else {
        total += item.price_after_discount;
        totalHt += item.priceHt;
        // }
      }
      total +=bundlePrice
    }
    if (apply === "order") {
      setTotalPrice(
        parseFloat(
          total + (frais !== null ? frais : total * (taux / 100))
        ).toFixed(2)
      );
      setTotalPriceHt(
        parseFloat(
          totalHt + (frais !== null ? frais : total * (taux / 100))
        ).toFixed(2)
      );
    } else {
      setTotalPrice(parseFloat(total.toFixed(2)));
      setTotalPriceHt(parseFloat(totalHt.toFixed(2)));
    }
  }, [
    orders,
    selectedPromos,
    // frais, taux
  ]);

  { /*----encryption / decryption functions----*/}

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), "secret_key").toString();
  };

  const decryptData = (encryptedData) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

      // Check if the decrypted text is valid JSON
      if (!decryptedText) {
        throw new Error("Decryption failed or returned empty string.");
      }

      return JSON.parse(decryptedText);
    } catch (error) {
      console.error("Error during decryption or parsing:", error);
      return null; // or handle the error as appropriate
    }
  };


  
const addItem2 = (productId,orderKey) => { 
  // Retrieve and decrypt order data from localStorage for the specific store
  const cryptedOrder = localStorage.getItem("OrderStoreWithID" + IDSTORE);
  
  let allOrders = [];
  if (cryptedOrder) {
    allOrders = decryptData(cryptedOrder); // Convert to JavaScript object
    console.log("allOrders", allOrders);
  }

  // Map through existing orders to find the one with matching orderKey
  const updatedOrders = orders.map((order) => {
    if (order.orderKey === orderKey) {
      
      // Find the corresponding order in allOrders by orderKey
      const orderFromAllOrders = allOrders.find(
        (thisOrder) => thisOrder.orderKey === order.orderKey
      );

      // Remove the found order from allOrders
      allOrders.splice(allOrders.indexOf(orderFromAllOrders), 1);

      // Update quantity and store it in localStorage
      const newQuantity = order.quantity + 1;
      const localStorageKey = `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${productId}`;
      localStorage.setItem(
        localStorageKey,
        parseInt(localStorage.getItem(localStorageKey)) + 1
      );

      // Create updated order object with new quantity and price calculations
      const updatedOrder = {
        ...order,
        quantity: newQuantity,
        price: order.unitePrice * newQuantity,
        priceHt: order.unitePriceHt * newQuantity,
      };

      // Add updated order back to allOrders
      allOrders.push(updatedOrder);

      // Return the updated order in the map function
      return updatedOrder;
    }

    // Return unmodified order for non-matching items
    return order;
  });

  // Dispatch updated orders to Redux store
  store.dispatch(setOrder({ order: updatedOrders }));

  // Encrypt and store the updated allOrders in localStorage
  const encryptedOrder = encryptData(allOrders);
  localStorage.setItem(`OrderStoreWithID${IDSTORE}`, encryptedOrder);
};
  const addItem = (productId, id) => {
    const cryptedOrder = localStorage.getItem("OrderStoreWithID" + IDSTORE);

    let allOrders = [];
    if (cryptedOrder) {
      allOrders = decryptData(cryptedOrder); // Convert to JavaScript object
      console.log("allOrders",allOrders);
      
    }
    const updatedOrders = orders.map((order, index) => {
      if (index === productId) {
        
        const orderFromAllOrders = allOrders.find(
          (thisOrder) => thisOrder.orderKey == order.orderKey
        );
        allOrders.splice(allOrders.indexOf(orderFromAllOrders), 1);
        const newQuantity = order.quantity + 1;
        localStorage.setItem(
          `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${id}`,
          parseInt( localStorage.getItem( `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${id}`)
          ) + 1
        );
        const aa = {
          ...order,
          quantity: newQuantity,
          price: order.unitePrice * newQuantity,
          priceHt: order.unitePriceHt * newQuantity,
        };
        allOrders.push(aa);
        return {
          ...order,
          quantity: newQuantity,
          price: order.unitePrice * newQuantity,
          priceHt: order.unitePriceHt * newQuantity,
        };
      }
      return order;
    });
    store.dispatch(setOrder({ order: updatedOrders }));
    const encryptedOrder = encryptData(allOrders);

    localStorage.setItem(`OrderStoreWithID${IDSTORE}`, encryptedOrder);
  };
  const deleteItem2 = (productId, orderKey) => {
    // Retrieve and decrypt order data from localStorage for the specific store
    const cryptedOrder = localStorage.getItem("OrderStoreWithID" + IDSTORE);
    let allOrders = [];
    
    // If there's encrypted data, decrypt it
    if (cryptedOrder) {
      allOrders = decryptData(cryptedOrder);
    }
  
    // Map through existing orders to find the one with matching orderKey
    const updatedOrders = orders
      .map((order) => {
        if (order.orderKey === orderKey) {
          // Find the corresponding order in allOrders by orderKey
          const orderFromAllOrdersIndex = allOrders.findIndex(
            (thisOrder) => thisOrder.orderKey === order.orderKey
          );
  
          if (order.quantity > 1) {
            // Decrement quantity and update price
            const newQuantity = order.quantity - 1;
            const newPrice = parseFloat((order.unitePrice * newQuantity).toFixed(3));
            const newPriceHt = parseFloat((order.unitePriceHt * newQuantity).toFixed(3));
  
            // Update localStorage quantity
            const localStorageKey = `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${productId}`;
            localStorage.setItem(
              localStorageKey,
              parseInt(localStorage.getItem(localStorageKey)) - 1
            );
  
            // Update the order in allOrders
            allOrders[orderFromAllOrdersIndex].quantity = newQuantity;
            allOrders[orderFromAllOrdersIndex].price = newPrice;
            allOrders[orderFromAllOrdersIndex].priceHt = newPriceHt;
  
            // Return the updated order
            return {
              ...order,
              quantity: newQuantity,
              price: newPrice,
              priceHt: newPriceHt,
            };
          } else {
            // If quantity is 1, remove the item
            const localStorageKey = `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${productId}`;
            const currentQuantityInCart = parseInt(localStorage.getItem(localStorageKey));
  
            // Remove or decrement from localStorage
            if (currentQuantityInCart === 1) {
              localStorage.removeItem(localStorageKey);
            } else {
              localStorage.setItem(localStorageKey, currentQuantityInCart - 1);
            }
  
            // Remove the order from allOrders
            allOrders.splice(orderFromAllOrdersIndex, 1);
  
            // Return null to exclude this order from the updated array
            return null;
          }
        }
  
        // Return the unmodified order for non-matching items
        return order;
      })
      .filter(Boolean); // Remove null entries (i.e., deleted orders)
  
    // Dispatch updated orders to Redux store
    store.dispatch(setOrder({ order: updatedOrders }));
  
    // Encrypt and store the updated allOrders in localStorage
    const encryptedOrder = encryptData(allOrders);
    localStorage.setItem(`OrderStoreWithID${IDSTORE}`, encryptedOrder);
  };
  
  

  const deleteItem = (productId, id) => {
    const cryptedOrder = localStorage.getItem("OrderStoreWithID" + IDSTORE);
    let allOrders = [];
    if (cryptedOrder) {
      allOrders = decryptData(cryptedOrder); // Convert to JavaScript object
    }
    const updatedOrders = orders
      .map((order, index) => {
        if (index === productId) {
          const orderFromAllOrdersIndex = allOrders.findIndex(
            (thisOrder) => thisOrder.orderKey == order.orderKey
          );
          // Check if quantity is greater than 1
          if (order.quantity > 1) {
            // If yes, decrement the quantity and update the price
            const newQuantity = order.quantity - 1
            const newPrice = parseFloat(
              (order.unitePrice * newQuantity).toFixed(3)
            );
            const newPriceHt = parseFloat(
              (order.unitePriceHt * newQuantity).toFixed(3)
            );
            localStorage.setItem(
              `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${id}`,
              parseInt(
                localStorage.getItem(
                  `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${id}`
                )
              ) - 1
            );
            allOrders[orderFromAllOrdersIndex].quantity = newQuantity
            allOrders[orderFromAllOrdersIndex].price = newPrice
            allOrders[orderFromAllOrdersIndex].priceHt = newPriceHt
            console.log("allOrders[orderFromAllOrdersIndex]",allOrders[orderFromAllOrdersIndex]);
            
            return {
              ...order,
              quantity: newQuantity,
              price: newPrice,
              priceHt: newPriceHt,
            };
          } else {
            // If quantity is 1, remove the item by not including it in the updated array
            const ttPanier = parseInt(
              localStorage.getItem(
                `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${id}`
              )
            );

            if (ttPanier === 1) {
              localStorage.removeItem(
                `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${id}`
              );
            } else {
              localStorage.setItem(
                `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${id}`,
                parseInt(
                  localStorage.getItem(
                    `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${id}`
                  )
                ) - 1
              );
            }
            allOrders.splice(allOrders.indexOf(allOrders[orderFromAllOrdersIndex]), 1);
            console.log("deleteitem/allOrders",allOrders);
            
            return null;
          }
        }
        return order;
      })
      .filter(Boolean); // Remove null entries from the array
    store.dispatch(setOrder({ order: updatedOrders }));
    const encryptedOrder = encryptData(allOrders);
    localStorage.setItem(`OrderStoreWithID${IDSTORE}`, encryptedOrder);
  };

  // Edit  item 
  const editItem = (productId, orderKey) => {
    const product = products.find((product) => product._id === productId);
    const order = orders.find((product) => product.orderKey === orderKey);
    store.dispatch(
      setOpenModal({
        product: product,
        open: true,
      })
    );
    //-----used in selectOption-----
    // checkedIndices key : `${selectedSize.size}-${sectionIndex}-${checkboxIndex}`
    // checkedOption key : `${sectionIndex}-${subId}-${optionId}`
    // counter key : `${sectionIndex}-${optionId}`
    let checkedIndices = [];
    let checkedOptions = [];
    let counter = {};
    if (order && order.options && order.options.length > 0) {
      order.options.forEach((option) => {
        // For checkedIndices: `${selectedSize.size}-${sectionIndex}-${checkboxIndex}`
        if (
          option.checkboxIndex !== undefined &&
          option.sectionIndex !== undefined
        ) {
          // Adjusted logic to push keys based on option.quantity
          for (let i = 0; i < option.quantity; i++) {
            checkedIndices.push(`${order.product.size}-${option.sectionIndex}-${option.checkboxIndex}`);
          }
        }
        //for counter : `${sectionIndex}-${optionId}`
        // For counter: `${sectionIndex}-${optionId}`
        if (option.sectionIndex !== undefined && option.id !== undefined) {
          counter[`${option.sectionIndex}-${option.id}`] = option.quantity; // Initialize counter value as 0
        }
        // For checkedOptions: `${sectionIndex}-${subId}-${optionId}`
        if (option.options.length > 0) {
          option.options.forEach((option) => {
            if (option.sectionIndex !== undefined && option.subId !== undefined && option.optionId !== undefined) {
              checkedOptions.push(`${option.sectionIndex}-${option.subId}-${option.optionId}`);
            }
          });
        }
      });
    }
    store.dispatch(setEditOrder(
      {
        open: true,
        objectOptions: order.options,
        checkedIndices: checkedIndices,
        checkedOptions: checkedOptions,
        counter: [counter],
        orderQuantity: order.quantity,
        note: order.note,
        size: order.product.size,
        order: order,
      }));
    setShow(false);
  };
  const deletePromo = (promoIndex) => {
    // Make a copy of selectedPromos before modifying it
    let updatedPromo = [...selectedPromos];

    // Check if the promoIndex is valid
    if (promoIndex >= 0 && promoIndex < updatedPromo.length) {
      // Remove the element at the specified index
      updatedPromo.splice(promoIndex, 1);

      // console.log(updatedPromo);

      // Dispatch the action with the updated selectedPromos
      store.dispatch(deletePromos({ selectedPromos: updatedPromo }));
    } else {
      console.error(`Invalid promo index: ${promoIndex}`);
    }
  };

  const deletecart = () => {
    store.dispatch(setOrder({ order: [] }));
    localStorage.removeItem("OrderStoreWithID" + IDSTORE);
    store.dispatch(resetPromo());

    // Initialize array to store order IDs
    const productsToDelete = [];

    orders.forEach((order) => {
      productsToDelete.push(order.product.id);
    });

    productsToDelete.forEach((productId) => {
      const quantityKey = `QuantityInCart_ForStoreWithId${IDSTORE}_ForProductWith${productId}`;
      localStorage.removeItem(quantityKey);
    });
    setConfirmationClearCart(false)
  };

  const sumTotalInPromo = (product) => {
const bundlePrice = product.promo.bundlePrice

    let total = bundlePrice;
    for (let j = 0; j < product.products.length; j++) {
      const item = product.products[j];
      // if (apply === "product") {
      //   total += item.price_after_discount + (frais !== null ? frais : product.price * (taux / 100));

      // }
      // else {
      total += item.price_after_discount;
      // }
    }
    // if (apply === "order") {
    //   return total + (frais !== null ? frais : total * (taux / 100));
    // }
    // else {
    return total;
    // }
  };

  const checkout = async () => {
    // store.dispatch(setmodelchekoutButton({ modelchekoutButton: true }));
    if (isLoggedIn) {
      setModalCheckout(true);

      setShow(false);
      store.dispatch(setScroll({ scroll: 0 }));
    } else {
 if (delivery !== undefined &&
  delivery === false){
    store.dispatch(setModelGuest({ ModelGuest: true }));

  }else{
    store.dispatch(setModalPrincipal({ modalPrincipal: true }));

  }
      
 
      setShow(false);
    }
  };

  return (
    <>
      <button onClick={handleShow} className="btn ">
        <ShoppingCartIcon style={{ color: "#eb1700" }} />
      </button>
      <StyledOffCanvas
        show={show}
        onHide={handleClose}
        scroll={true}
        placement="end"
      >
        <StyledOffCanvasHeader closeButton style={{ display: "block" }}>
          <Offcanvas.Title>
          {vendeur &&<div><h4>vendeur :{vendeur}</h4><h2>{t(" Order")} :</h2></div>}
            {!vendeur&&<h2>{t("Your Order")} </h2>}


          </Offcanvas.Title>
          {(orders && orders.length > 0) ||
            (selectedPromos && selectedPromos.length > 0) ? (
            <TotalPrice>
              {/* <h6>
                Total Price: <span className="price-value">{totalPrice}</span>
              </h6> */}
              <CommanderButton onClick={checkout}>
                {t("Checkout ")}
                {/* {parseFloat(totalPrice).toFixed(2)} */}
                {apply === "product" ? (
                  <>
                    {parseFloat(totalPrice).toFixed(2)}
                    {currency}
                  </>
                ) : (
                  <>
                    {(
                      parseFloat(totalPrice) -
                      (frais !== null ? frais : totalPrice * (taux / 100))
                    ).toFixed(2)}
                    {currency}
                    <p style={{ fontSize: "14px", fontWeight: "normal" }}>
                      {" "}
                      + {modeName} fee {currency}
                      {frais !== null
                        ? parseFloat(frais).toFixed(2)
                        : parseFloat(totalPrice * (taux / 100)).toFixed(2)}
                    </p>
                  </>
                )}
              </CommanderButton>
              <ClearCart onClick={() => setConfirmationClearCart(true)} >
                <DeleteIcon />
              </ClearCart>
              <Dialog open={confirmationClearCart}
              // style={{width:"500px",height:"500px",}}
               maxWidth="md"
              //  fullWidth
              >
                <div style={styles.dialog}>
                <ButtonClose
                onClick={() =>{setConfirmationClearCart(false)}}
                >
          <ClearIcon style={{ fontSize: "27px" }} />
        </ButtonClose>
        <p style={styles.text}>{t("Cart will be cleared , are you sure")} ? </p>
              <div style={styles.buttonContainer}>
        <button style={styles.buttonYes} onClick={deletecart}>OK</button>
        {/* <button style={styles.buttonNo} onClick={() =>{setConfirmationClearCart(false)}}>No</button> */}
      </div>
    </div>
                </Dialog>
            </TotalPrice>
          ) : null}
        </StyledOffCanvasHeader>

        <StyledOffCanvasBody>
          {orders &&
            orders.length > 0 &&
            (() => {
              const groupedOrders = orders.reduce((pd, order) => {
                const existingOrder = pd.find(
                  (o) =>
                    o.product.id === order.product.id &&
                    JSON.stringify(o.options) === JSON.stringify(order.options) && o.product.size === order.product.size 
                );

                if (existingOrder) {
                  existingOrder.quantity += order.quantity;
                } else {
                  pd.push({ ...order });
                }
                return pd;
              }, []);
              
              return groupedOrders
              .sort((a, b) => a.index - b.index) 
              .map((order, index) => {
                
                const itemTotalPrice =
                  order.quantity *
                  (order.product.price + frais+
                    order.options.reduce(
                      (sum, option) =>
                        sum +
                        option.price_opt +
                        option.options.reduce(
                          (sumOpt, opt) => sumOpt + opt.price,
                          0
                        ),
                      0
                    ));

                return (
                  <OrderItem
                    key={`${order.product.id}-${JSON.stringify(order.options)}`}
                  >
                    <OrderHeader>
                      <div>
                        <ItemImage
                          src={`${ImageURI}/combined-uploads/${order.product.image}`}
                        ></ItemImage>
                      </div>
                      <ItemModification>
                        <ItemQuantity>
                          {order.quantity > 1 ? (
                            <button
                              className="btn"
                              onClick={() =>
                                deleteItem2(order.product.id,order.orderKey)
                              }
                            >
                              <RemoveCircleIcon />
                            </button>
                          ) : (
                            <button
                              className="btn"
                              onClick={() =>
                                deleteItem2(order.product.id,order.orderKey)
                              }
                            >
                              <DeleteIcon />
                            </button>
                          )}
                          {/*----------order quantity----------- */}
                          <button className="btn">{order.quantity}</button>
                          {/*------------------------------------*/}
                          <button
                            className="btn"
                            onClick={() => addItem2(order.product.id,order.orderKey)}
                          >
                            <AddCircleIcon />
                          </button>
                        </ItemQuantity>
                        {order.product.withOptions >0 && 
                        <div
                          style={{
                            boxShadow:
                              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "40px",
                            width: "40px",
                            cursor: "pointer",
                          }}
                        >
                          <EditIcon
                            onClick={() =>
                              editItem(order.product.id, order.orderKey)
                            }
                          />
                        </div>
                        }
                      </ItemModification>
                    </OrderHeader>
                    <ItemName>
                    <h5>{`${order.product.name} ${ order.product.size !== "standard"
                        ? `(${order.product.size})`
                        : ""
                        }`}</h5>
                      <h5>
                        {apply === "product"
                          ? parseFloat(
                              order.product.price +
                                (frais !== null
                                  ? frais
                                  : order.product.price * (taux / 100))
                            ).toFixed(2)
                          : parseFloat(order.product.price).toFixed(2)}
                        {currency}
                      </h5>
                    </ItemName>

                    <ProductPanierDescription>
                      {order.product.description}
                    </ProductPanierDescription>
                    {order.options.length > 0 ? (
                      <Options>
                        {order.options.map((option, index) => (
                          <div key={index}>
                            {index === 0 ||
                              option.optionGroupeName !==
                              order.options[index - 1].optionGroupeName ? (
                              <OptionsHeading>
                                {option.optionGroupeName}
                              </OptionsHeading>
                            ) : null}
                            <ProductOptions>
                              <div className="flex justify-between">
                                <Option>{option.name}</Option>
                                {option.price_opt > 0 ? (
                                  <Option>
                                    {parseFloat(option.price_opt).toFixed(2)}
                                    {currency}
                                    {/* options: selectedPromos[i].products[j].options, */}
                                  </Option>
                                ) : null}
                              </div>
                              {option.options.map((opt) => (
                                <div className="flex justify-between">
                                  <Option>&nbsp;+{opt.name}</Option>
                                  {opt.price > 0 ? (
                                    <Option>
                                      {parseFloat(opt.price).toFixed(2)}
                                      {currency}
                                    </Option>
                                  ) : null}
                                </div>
                              ))}
                            </ProductOptions>
                          </div>
                        ))}
                      </Options>
                    ) : null}
                    {/* {parseFloat(order.price).toFixed(2)} */}
                    {/* {apply === "product" && (
                  <>
                    <ProductOptions>Extra fees</ProductOptions>
                    <div className="flex justify-between">
                      <Option>{modeName} fee </Option>
                      {frais === 0 || taux === 0 ? (
                        <Option>Free</Option>
                      ) : (
                        <Option>
                          {currency}
                          {frais !== null
                            ? frais
                            : parseFloat(order.price).toFixed(2) *
                            (taux / 100)}
                        </Option>
                      )}
                    </div>
                  </>
                )} */}
                    <PriceContainer>
                      <h5>Total :</h5>
                      <h5>
                        {itemTotalPrice.toFixed(2)}
                      
                        {currency}
                        {/* {apply === "product"
                      ? parseFloat(
                        order.price +
                        (frais !== null
                          ? (frais * order.quantity)
                          : order.price * ((taux / 100) * order.quantity))
                      ).toFixed(2)
                      : parseFloat(order.price).toFixed(2)} */}
                      </h5>
                    </PriceContainer>
                  </OrderItem>
                );
              });
            })()}

          {selectedPromos &&
            selectedPromos.length > 0 &&
            selectedPromos.map((product, ind) => (
              <PromoContainer key={product.promo._id}>
                <PromoDeleteButton>
                  <button onClick={() => deletePromo(ind)}>
                    <DeleteIcon />
                  </button>
                </PromoDeleteButton>
                <PromoTitle key={ind}> {product.promo.name}</PromoTitle>

                {product.products.map((order, index) => {
                  const promoItemTotalPrice =
                    order.quantity *
                    (order.price_after_discount !== order.price &&
                    product.promo.discount === 100
                      ?order.price_after_discount
                      : order.price_after_discount);

                  return (
                    <div key={order.product.id}>
                      <OrderHeader>
                        <div>
                          <ItemImage
                            src={`${ImageURI}/combined-uploads/${order.product.image}`}
                          ></ItemImage>
                        </div>
                        {/* <ItemQuantity>
                        {order.quantity > 1 ? (
                          <button
                            className="btn "
                            onClick={() => deleteItem(index)}
                          >
                            <RemoveCircleIcon />
                          </button>
                        ) : (
                          <button
                            className="btn"
                            onClick={() => deleteItem(index)}
                          >
                            <DeleteIcon />
                          </button>
                        )}
                        <button onClick={() => deletePromo(ind)}>
                          <DeleteIcon />
                        </button>

                        <button className="btn">{order.quantity} </button>
                        <button className="btn " onClick={() => addItem(index)}>
                          <AddCircleIcon />
                        </button>
                      </ItemQuantity> */}
                      </OrderHeader>
                      <ItemName>
                        <h5>{`${order.product.name} ${
                          order.product.size !== "S"
                            ? `(${order.product.size})`
                            : ""
                        }`}</h5>
                        <h5>
                          {order.price_after_discount !== order.price &&
                          product.promo.discount === 100 ? (
                            <span>{t('Free')}</span>
                          ) : order.price_after_discount !== order.price ? (
                            <>
                              {parseFloat(order.product.price).toFixed(2) > 0   && (
                              <span
                                style={{
                                  textDecorationLine: "line-through",
                                  marginRight: "5px",
                                  color: "#a6a6a6",
                                }}
                              >
                                {`${parseFloat(order.product.price).toFixed(
                                  2
                                )}${currency}`}
                              </span>
                                )}
                              {parseFloat(order.product.price).toFixed(2) > 0   && (
                              <span>
                                {`${parseFloat(
                                  order.product.price -
                                    (order.product.price *
                                      product.promo.discount) /
                                      100
                                ).toFixed(2)}${currency}`}
                              </span>
                              )}
                            </>
                          ) : (<div> 
                            {parseFloat(order.product.price).toFixed(2) > 0   && (
                            `${parseFloat(order.product.price).toFixed(
                              2
                            )}${currency}`
                          )}</div>
                          )}
                        </h5>
                      </ItemName>

                      <ProductPanierDescription>
                        {order.product.description}
                      </ProductPanierDescription>
                      {order.options.length > 0 ? (
                        <Options>
                          {order.options.map((option, index) => (
                            <div key={index}>
                              {index === 0 ||
                              option.optionGroupeName !==
                                order.options[index - 1].optionGroupeName ? (
                                <OptionsHeading>
                                  {option.optionGroupeName}
                                </OptionsHeading>
                              ) : null}
                              <ProductOptions>
                                <div className="flex justify-between">
                                  <Option>{option.name}</Option>
                                  {option.price_opt > 0 ? (
                                    <Option>
                                      {parseFloat(option.price_opt).toFixed(2)}
                                      {currency}
                                    </Option>
                                  ) : null}
                                </div>
                                {option.options.map((opt) => (
                                  <div className="flex justify-between">
                                    <Option>&nbsp;+{opt.name}</Option>
                                    {opt.price > 0 ? (
                                      <Option>
                                        {parseFloat(opt.price).toFixed(2)}
                                        {currency}
                                      </Option>
                                    ) : null}
                                  </div>
                                ))}
                              </ProductOptions>
                            </div>
                          ))}
                        </Options>
                      ) : null}
                      {/* {apply === "product" && (
                      <>
                        <ProductOptions>Extra fees</ProductOptions>
                        <div className="flex justify-between">
                          <Option>{modeName} fee </Option>
                          {frais === 0 || taux === 0 ? (
                            <Option>Free</Option>
                          ) : (
                            <Option>
                              {currency}
                              {frais !== null
                                ? frais
                                : parseFloat(order.price).toFixed(2) *
                                (taux / 100)}
                            </Option>
                          )}
                        </div>
                      </>
                    )} */}
                      <PriceContainer>
                        <h6>SubTotal :</h6>
                        <h6>
                          {promoItemTotalPrice.toFixed(2)} 
                          {currency}
                          {/* {apply === "product"
                          ? parseFloat(
                            order.price_after_discount +
                            (frais !== null
                              ? frais
                              : order.price_after_discount * (taux / 100))
                          ).toFixed(2)
                          : parseFloat(order.price_after_discount).toFixed(2)} */}
                        </h6>
                      </PriceContainer>
                      <hr />
                    </div>
                  );
                })}
                <PriceContainer>
                  <h5>Total :</h5>
                  <h5>
                    {parseFloat(sumTotalInPromo(product)).toFixed(2)}
                    {currency}
                  </h5>
                </PriceContainer>
              </PromoContainer>
            ))}
        </StyledOffCanvasBody>
      </StyledOffCanvas>
      <Checkout
        modalCheckout={modalCheckout}
        setModalCheckout={setModalCheckout}
        // apply={apply}
        // modeName={modeName}
        // frais={frais}
        // taux={taux}
        sumTotalInPromo={sumTotalInPromo}
        totalPrice={totalPrice}
        totalPriceHt={totalPriceHt}
      />

    </>
  );
}

const StyledOffCanvas = styled(Offcanvas)`
  background-color: #f5f5f5;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  @media (max-width: 768px) {
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    width: 90% !important;
  }
`;

const StyledOffCanvasHeader = styled(Offcanvas.Header)`
  padding: 20px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  .btn-close {
    position: absolute;
    top: 25px;
    right: 35px;
  }
`;

const StyledOffCanvasBody = styled(Offcanvas.Body)`
  padding: 20px;
  background-color: #e6e6e6;
`;

const PriceValue = styled.span`
  color: #eb1700;
`;

const CommanderButton = styled.button`
  // background-color: #eb1700;
  background:#eb1700;
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 18px;
  margin-left: 10px;
  padding: 10px;
  &:hover {
    background-color: #eb1700;
  }
`;

/* Order item styles */

const OrderItem = styled.div`
  margin-bottom: 20px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
`;

const OrderHeader = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const ItemName = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ItemModification = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
const ItemImage = styled.img`
  width: 150px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 15px;
`;

const ItemQuantity = styled.div`
  display: flex;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 25px;
  height: 40px;
  width: fit-content;
  // margin-right: 40%;
`;

const ProductPanierDescription = styled.p`
  font-size: 14px;
  // font-style: italic;
  margin-bottom: 10px;
  color: #666;
`;

const Options = styled.div`
  margin-bottom: 10px;
`;

const ProductOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OptionsHeading = styled.h6`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Option = styled.p`
  font-size: 14px;
  // font-style: italic;
  margin-bottom: 5px;
  color: #666;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;

const ClearCart = styled.div`
  display: flex;
  align-items: flex-end;
  color: #fff;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #dc3545;
  border-color: #dc3545;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    cursor: pointer;
  }
    
`;

const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  font-weight: bold;
`;

const PromoTitle = styled.h6`
  margin-left: 3px;
  font-size: 20px;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
`;

const PromoContainer = styled.div`
  margin-bottom: 20px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
`;

const PromoDeleteButton = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 40%;
  border-radius: 25px;
  text-align: center;
  padding: 6px;
  margin-bottom: 10px;
`;
const styles = {
  dialog: {
    // padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    width: '350px',
    height:'150px',
    textAlign: 'center',
    display:'flex',
    flexDirection :"column",
    justifyContent:'center',
  },
  text: {
    marginBottom: '10px',
    fontFamily: 'Roboto Medium',
    fontWeight: 'bold'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop:'15px'
  },
  buttonYes: {
    background: '#EB1700',
    color:' #fff',
    border:' none',
    borderRadius: '15px',
    fontSize: '18px',
    marginLeft: '10px',
    padding: '10px',
width:"116px",
height:"44px"
  },
  buttonNo: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor:'#f11c1c',
    border:'1px solid #f11c1c'
  },
};
const ButtonClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 2px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;