export default {
    //home.js
    "Our Partners": "I nostri partner",

    //AdressInput.js
    "type your address": "digita il tuo indirizzo",

    //StoreScreen.js
    "km away": " km ",
    "Closed 🔴": "Chiuso 🔴",
    "Open 🟢": "Aperto 🟢",

    //DateModel.js
    "Monday": "Lunedì",
    "Tuesday": "Martedì",
    "Wednesday": "Mercoledì",
    "Thursday": "Giovedì",
    "Friday": "Venerdì",
    "Saturday": "Sabato",
    "Sunday": "Domenica",
    "Closed": "Chiuso ",
    "Service Hours": "Orari di servizio",
    "Language": "Lingua",

    // menu.js
    "Our promos": "Le nostre promozioni",

    //selectoption.js
    "(optional)": "(Facoltativo)",
    "Required" : "Obbligatorio",
    "select up to" : "seleziona fino a",
   
    // SubmitOrder.js
    "select at least": "seleziona almeno",
    "of": "di",
    "Quantity": "Quantità",
    "change your order": "cambia il tuo ordine",

    //submitOrderPromo.js
    "Finish" : "Concludi",
    "Next":"Avanti",

    //ModelPrincipal.js
    "Email Address" : "Indirizzo email",
    "Continue":"Continua",
    "Or":"Oppure",
    "Continue with Google":"Continua con Google",
    "Email does not exist":"L'email non esiste",

    //ModalSignup.js
    "First Name" : "Nome",
    "Last Name": "Cognome",
    "Email" :"E-mail",
    "Enter Phone Number" : "Inserisci il numero di telefono",
    "Password":"Password",
    "Confirm Password":"Conferma la password",
    "Passwords do not match":"Le password non corrispondono",
    "Signup": "Registrati",
    "Sign up": "Registrati",
    "Male" : "Maschio",
    "Female" : "Femmina",

    //ModelLogin.js
    "Login" : "Accedi",
    "Sign in":"Accedi",
    "Forget password":"Password dimenticata",
    "Add to cart" :"Aggiungi al carrello",
    "kitchen note":"Nota cucina",
    "Leave your note about food here":"Lasciali qui la tua nota sul cibo",
    // Cart.js
    "Your Order":"Il tuo ordine",
    "Checkout ":"Pagamento ",
    "Checkout":"Pagamento",
    "tax-exclusive":"Escluso IVA",
    "ASAP":"il prima possibile",
    "By checking this box, you confirm that your order to":"Selezionando questa casella, confermi che il tuo ordine a",
    "This action is irreversible and commits to the purchase as outlined.":"Questa azione è irreversibile e impegna l'acquisto come descritto.",
    "Minimum order value":"Valore minimo dell'ordine",
    " for ":" per il metodo",
    "is ":"è ",

    //checkout.js
    "Contact" : "Contatto",
    "Name" : "Nome",
    "Email": "E-mail",
    "phone number":"numero di telefono",
    "Order in Advance":"Ordina in anticipo",
    "Reset":"Ripristina",
    "Month D, YYYY HH:MM":"Mese G, AAAA HH:MM",
    "Payment Method":"Metodo di pagamento",
    "Cash":"Contante",
    "EPT":"POS",
    "Credit/Debit Card":"Carta di credito/debito",
    "Place Your Order":"Fai il tuo ordine",
   
    //modalresetPassword.js
    "Email has been send, check your email!":"E-mail inviata, controlla la tua e-mail!",
    "Resend Email":"Rinvia e-mail",
    //firstPage.js
    "All Stores":"Tutti i ristoranti",
    'Nearby Stores':"Ristoranti nelle vicinanze",
    //ModelEmailSend.js
    "Email has been send, check your email!":"E-mail inviata, controlla la tua e-mail!",
    "Resend Email":"Rinvia e-mail",
    // AllStoresScreen.js
    "All Stores":"Tutti i ristoranti",
    // ModalModes.js
    "Select Your Mode" :"Seleziona il tuo metodo",
    "Yes" :"Sì",
    "No":"No",
    //SelectStore.js
    "Select Store":"Seleziona un ristorante",
    "Confirm" : "Conferma",
    "Delivery":"Consegna",
    "Takeaway":"Da asporto",
    "Dine-in":"In loco",

    //  ProductModel
    "leave your additional note":"lascia la tua nota aggiuntiva",
    "Additional note":"Nota aggiuntiva",


    // SubmitOrder.js
    "select at least":"seleziona almeno",
    "of":"di",
    "Quantity":"Quantità",



    //OrdersHistory
    "TIME":"TEMPO",
    "MODE":"METODO",
    "STATUS":"STATO",
    "PAYMENT":"PAGAMENTO",
    "TOTAL":"TOTALE",
    "VIEW":"VISTA",
    "accepted":"accettato",
    "ready":"pronto",
    "rejected":"rifiutato",
    "missed":"perso",
    "pending":"In attesa",


    //OrderHistoryModal.js
    "(Without service fees)":"(Senza commissioni di servizio)",
    "ORDER ID":"ID ORDINE",
    "Total price":"Prezzo totale",
    "Add note":"Aggiungi nota",
    "Leave your note here":"Lascia la tua nota qui",
    "Order Time":"Orario dell'ordine",
    "Schedule Order":"Ordina in anticipo",
    "Minutes":"Minuti",
    "Seconds":"Secondi",
    "left.":"restanti.",
    "We regret to inform you that your order has been Missed":"Ci dispiace informarti che il tuo ordine è stato perso",
    "We regret to inform you that your order has been Rejected":"Ci dispiace informarti che il tuo ordine è stato rifiutato",
    "We are pleased to inform you that your order has been Accepted":"Siamo lieti di informarti che il tuo ordine è stato accettato",
    "We are pleased to inform you that your order is Ready":"Siamo lieti di informarti che il tuo ordine è pronto",
    "Free":"Gratis",
    "By checking this box, you confirm that you are certain about proceeding with this specific order to":"Selezionando questa casella, confermi che sei certo di procedere con questo ordine specifico a",
    "This action is irreversible and commits to the purchase as outlined. Please review your selections carefully before confirming":"Questa azione è irreversibile e impegna l'acquisto come descritto. Rivedi attentamente le tue scelte prima di confermare",
    "The restaurant is closed, ASAP mode is not available":"Il ristorante è chiuso, la modalità ASAP non è disponibile.",

    "Craving something delicious? Order your favorite meals from our platform and enjoy fast, easy, and reliable food delivery. Discover a wide variety of cuisines, exclusive deals, and hassle-free ordering. Satisfaction guaranteed with every bite.":"Hai voglia di qualcosa di delizioso? Ordina i tuoi piatti preferiti sulla nostra piattaforma e goditi una consegna rapida, facile e affidabile. Scopri una vasta gamma di cucine, offerte esclusive e ordini senza problemi. Soddisfazione garantita ad ogni morso.",
    "Get connected with us on social networks":"Connettiti con noi sui social network",
    "Pick your 1st choice":"Scegli la tua prima opzione",
    "Previous choices":"Opzioni precedenti",
    "Now pick your":"Ora scegli il tuo",
    "choice.":"scelta.",
    "th":"°",
    "rd":"°",
    "nd":"°",
    "Chosen size":"Taglia scelta",
    "Cart will be cleared , are you sure":"Il carrello verrà svuotato, sei sicuro?",
    "From ":"da "
}
