import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTranslation } from "react-i18next";

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 60px;
  margin-bottom: 10px;
  outline: none;  
`;

const InputButton = styled.div`
  display: flex;
  position: relative;
`;
const Button2= styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: ${(props) => (props.hasSuggestions ? "-400px" : "1px")};
  top: 41%;
  transform: translateY(-50%);
  background-color:white;
  border-radius: 50% 50% 50% 50%; 
  border:0px solid gray !important;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
  color:#403f3f;
`;

const Button = styled.button`
 background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 9px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: ${(props) => (props.hasSuggestions ? "-400px" : "1px")};
  top: 40%;
  transform: translateY(-50%);
  background-color:white;
  border-radius: 50% 50% 50% 50%; 
  border:0px solid gray !important;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
  color:#403f3f;
`;
const List = styled.div`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 60%;               /* Set width to 80% */
  position: absolute;
  left: 22%;                /* Position the list at the center */
  z-index: 1001;
  background-color: #ffffff;
  border-radius: 8px;
  max-height: 90%;
  overflow-y: auto;         /* Scroll when the list exceeds the max height */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);  /* Soft, professional shadow */
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;  /* Smooth fade-in and slide-up */
  background: #fff;
  margin-top: 4px;  /* Slight margin to give the dropdown some breathing space */

  @media (max-width: 480px) {
    width: 95%;  /* Ensure the list takes full width on smaller screens */
    left: 0;   
    margin:8px;   /* Align to left for smaller screens */
    transform: none;  /* Remove centering for small devices */
  }
`;

const ListItem = styled.div`
  padding: 12px 20px;
  font-size: 15px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 48px;  /* Maintain consistent height */
  transition: all 0.3s ease;  /* Smooth hover and active transitions */
  
  /* Add a subtle line between each item */
  border-bottom: 1px solid #e0e0e0; /* Light gray border between items */

  &:hover {
    background-color: #f4f7fa;  /* Soft hover effect */
    padding-left: 24px;  /* Expand padding on hover */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow on hover */
  }

  &:active {
    background-color: #e7eff4;  /* Slightly darker blue on click */
    transform: scale(0.98);  /* Shrink effect when clicked */
  }

  &:focus {
    outline: none;
    background-color: #e0f7fa;  /* Light blue for focus */
  }

  /* Optional: Icon Styling */
  & > .icon {
    margin-right: 12px;
    font-size: 18px;
    color: #0077cc;  /* Icon color */
  }

  /* Remove border on the last item */
  &:last-child {
    border-bottom: none;
  }
`;



const AddressIn = styled.div`
  width: ${(props) => props.width};
 

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const AddressInput = ({ onSelectLocation, calculateDistanceFn, width,inputValue2 }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1Ijoib3Vzc2FtYTAwOSIsImEiOiJjbHJodmFkc3gwMnZ6MmtwYWVqa2x6Yjl6In0.lPX7JfDDroOFDJh_DpSFYQ";

    
  useEffect(() => {
    inputValue2(inputValue)
    }, [inputValue]);

  useEffect(() => {
   

    if (selectedLocation) {
      onSelectLocation(selectedLocation);
      calculateDistanceFn(selectedLocation);
    }
  }, [selectedLocation]);

  // useEffect(() => {
  //   handleLocationChange2();
  // }, []);

  const handleInputChange = async (value) => {
    setInputValue(value);
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          value
        )}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
      );
      if (response.data.features) {
        const suggestions = response.data.features.map((feature) => ({
          label: feature.place_name,
          value: {
            lat: feature.center[1],
            lon: feature.center[0],
          },
        }));
        // Filter suggestions based on input value
        // const filteredSuggestions = suggestions.filter((suggestion) =>
        //   suggestion.label.toLowerCase().includes(value.toLowerCase())
        // );
        setOptions(suggestions);
      }
    } catch (error) {
      console.error("Error fetching location suggestions:", error.message);
    }
  };

  const handleLocationChange = (location) => {
    setInputValue(location.label);
    setSelectedLocation(location);
    setOptions([]);
    calculateDistanceFn(location);
  }; 
   const Delete = () => {
    setInputValue("");
    setOptions([]);
  };

  const handleLocationChange2 = async () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
      return;
    }

    try {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {

            const response = await axios.get(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
            );
            if (response.data.features && response.data.features.length > 0) {
              const address = response.data.features[0].place_name;
              const location = {
                label: address,
                value: { lat: latitude, lon: longitude },
              };
              
              setInputValue(location.label);
              setSelectedLocation(location);
              setOptions([]);
              calculateDistanceFn(location);
            }
          } catch (error) {
            console.error("Error fetching location data:", error.message);
            alert("Error fetching location data. Please try again.");
          }
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.log("The request to get user location timed out.");
              break;
            default:
              console.log("An unknown error occurred.");
              break;
          }
          console.error("Geolocation error:", error);
        }
      );
    } catch (error) {
      console.error("Error getting current location:", error.message);
    }
  };

  return (
    <AddressIn width={width ? width : "100%"}>
      <InputButton>
      {inputValue ==="" && (
  <Button onClick={handleLocationChange2}>
  <LocationOnIcon style={{ color: "#eb1700" }} />
</Button>
          )}
           {inputValue!==""&& (
  <Button2 onClick={Delete}> ✖ 
  </Button2>

          )}

    
        <Input
          type="text"
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          placeholder={t("type your address")}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </InputButton>
      <List>
  {options.map((option, index) => (
    <ListItem key={index} onClick={() => handleLocationChange(option)}>
      {/* Optional icon */}
      <LocationOnIcon className="icon"  style={{color:"red"}}/>
      <span>{option.label}</span>
      {/* Optionally add extra info like distance, address, etc */}
      <span>{option.distance || ''}</span>
    </ListItem>
  ))}
</List>
    </AddressIn>
  );
};

export default AddressInput;
