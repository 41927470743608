import { BaseURI } from "../..";
import { Executor } from "../../Executor";
// import { setLoggedInUser, setToken } from "./AuthSlice";

export const checkEmail = (data) => {
    console.log("data",data);
    
    return Executor({
        method: 'post',
        data,
        head: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        url: BaseURI + '/client/checkEmail',
        isSilent: true,
        successFun: (data) => {

        },
        withErrorToast: true,
        withSuccessToast: true,
    });
}
export const signup = (data) => {
    return Executor({
        method: 'post',
        data,
        url: BaseURI + '/client/signup',
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const login = (data) => {
    return Executor({
        method: 'post',
        data,
        url: BaseURI + '/client/login',
        isSilent: true,
        successFun: (data) => {

        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const UserExistOrNot = (email) => {
    return Executor({
        method: 'get',
      
        url: BaseURI + `/client/UserExistOrNot/${email}`,
        isSilent: true,
        successFun: (data) => {

        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const loginGuest = (userData) => {
    return Executor({
        method: 'post',
        url: BaseURI + '/client/loginGuest',
        data: userData,
        isSilent: true,
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const forgetPassword = (data) => {
    return Executor({
        method: 'post',
        data,
        url: BaseURI + '/client/forgetPassword',
        isSilent: true,
        successFun: (data) => {
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const sendEmail = (data) => {
    return Executor({
        method: 'post',
        url: BaseURI + `/client/sendVerification/${data}`,
        isSilent: true,
        successFun: (data) => {

        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}

export const verifyEmail = (userId) => {
    return Executor({
        method: 'put',
        url: BaseURI + `/client/verification/${userId}`,
        isSilent: true,
        successFun: (data) => {

        },
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const sendSMS = (userData) => {
    return Executor({
        method: 'post',
        url: BaseURI + `/client/sendSMS`,
        data: userData, // Pass user data to the API
        isSilent: true,
        successFun: (data) => {
            // Handle success response if needed
        },
        withErrorToast: false,
        withSuccessToast: false,
    });
};

export const getGuest = (number, nom, prenom) => {
    return Executor({
        method: 'get',
        url: BaseURI + `/client/guest/${number}/${nom}/${prenom}`,
        isSilent: true,
        withErrorToast: false,
        withSuccessToast: false,
    });
}

export const checkClient = (data) => {
    return Executor({
        method: 'post',
        data: data,
        url: BaseURI + `/client/checkclient`,
        isSilent: false,
        withErrorToast: false,
        withSuccessToast: false,
    });
}

export const resetPassword = (data, id, token) => {
    return Executor({
        head: {
            authorization: "Bearer " + token
        },
        method: 'put',
        data: data,
        url: BaseURI + `/client/resetPassword/${id}`,
        isSilent: false,
        withErrorToast: false,
        withSuccessToast: false,
    });
}
export const registerGuest = (userData) => {
    return Executor({
        method: 'post',
        url: BaseURI + '/client/registerGuest',
        data: userData,
        isSilent: true,
        withErrorToast: false,
        withSuccessToast: false,
    });
}